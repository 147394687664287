import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth';
import './Settings.css';
import { devLog, devError } from '../../utils/Logger';
import { toast } from 'react-toastify';
import axios from 'axios';
import localforage from 'localforage';
import ImportStringContext from '../../context/ImportStringContext';

const SettingsWidget = ({ isOpen, onClose, userSettings, setUserSettings, setCollectionResetTimestamp }) => {
    devLog("User settings prop in Settings.js:", userSettings);
    const { user } = useContext(AuthContext);
    const [showCataclysmContent, setShowCataclysmContent] = useState(userSettings.show_cataclysm_content);
    const [showUnobtainableContent, setShowUnobtainableContent] = useState(userSettings.show_unobtainable_content);
    const [playerFaction, setplayerFaction] = useState(userSettings.player_faction);
    const [playerRace, setplayerRace] = useState(userSettings.player_race);
    const [playerClass, setplayerClass] = useState(userSettings.player_class);
    const { setImportString } = useContext(ImportStringContext);
    const cacheEndpoints = ['achievements', 'heirlooms', 'mounts', 'pets', 'factions', 'titles', 'toys']

    const resetCollection = () => {
        if (window.confirm("Are you sure you want to reset your collected items? This action cannot be undone.")) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/resetCollection`, { userId: user.id })
                .then(response => {
                    if (response.data.success) {
                        toast.success("Collection reset successfully!", { autoClose: 5000 });

                        // Clear the cache for all obtained items
                        const obtainedItemsCacheKey = `obtained-items-${user.id}`;
                        localforage.removeItem(obtainedItemsCacheKey).then(() => {
                            devLog("Cleared cache for all obtained items after collection reset.");
                        });

                        // Clear the endpoint specific caches
                        cacheEndpoints.forEach(ep => {
                            const endpointSpecificCacheKey = `obtained-${ep}-${user.id}`;
                            localforage.removeItem(endpointSpecificCacheKey).then(() => {
                                devLog(`Cleared cache for endpoint ${ep} after collection reset.`);
                            });
                        });
                        setCollectionResetTimestamp(Date.now());
                        onClose();
                    } else {
                        toast.error("Error resetting collection.", { autoClose: 5000 });
                    }
                })
                .catch(err => {
                    toast.error("Error resetting collection.", { autoClose: 5000 });
                });
        }
    };

    // When the user confirms the import
    const handleImportDataClick = () => {
        const inputData = window.prompt("Please enter your data string from the ingame addon:");
        if (inputData) {
            // Validate the format using a regex
            const isValidFormat = /^((Achievements|Factions|Heirlooms|Mounts|Pets|Titles|Toys):(\d+(,\d+)*)?;?)+$/.test(inputData);
            if (!isValidFormat) {
                toast.error("#1 Error importing data - Invalid string");
                return;
            }

            devLog(inputData);
            // Set the import string in the shared context
            setImportString(inputData);

            toast.success("Data imported!");

            setCollectionResetTimestamp(Date.now());

            // Close the settings widget
            onClose();
        }
    };

    const LoggedOutReturn = () => (
        <div id="settingsWidget">
            <div>
                <h2>Register and login to change settings!</h2>
                <input
                    type="checkbox"
                    checked={showCataclysmContent}
                    onChange={(e) => setShowCataclysmContent(e.target.checked)}
                    disabled
                />
                <label>Show Cataclysm Conten</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    checked={showUnobtainableContent}
                    onChange={(e) => setShowUnobtainableContent(e.target.checked)}
                    disabled
                />
                <label>Show un-obtainable items</label>
            </div>
            <div>
                <label>Faction:</label>
                <select disabled value={playerFaction} onChange={(e) => {
                    const newFaction = e.target.value;
                    setplayerFaction(newFaction);
                }}>
                    {factionOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Race:</label>
                <select disabled value={playerRace} onChange={(e) => {
                    const newRace = e.target.value;
                    setplayerRace(newRace);
                }}>
                    {raceOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Class:</label>
                <select disabled value={playerClass} onChange={(e) => {
                    const newClass = e.target.value;
                    setplayerClass(newClass);
                }}>
                    {classOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <button disabled className="import-data-button" onClick={handleImportDataClick}>Import Data</button>
            <div className="button-container">
                <div className="reset-collection-button">
                    <button disabled onClick={resetCollection}>Reset Collection</button>
                </div>
                <div className="settings-buttons">
                    <button onClick={saveSettings}>Save</button>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );

    const LoggedInReturn = () => (
        <div id="settingsWidget">
            <div className="checkbox-group">
                <input
                    type="checkbox"
                    checked={showCataclysmContent}
                    onChange={(e) => setShowCataclysmContent(e.target.checked)}
                />
                <label>Show Cataclysm Content</label>
            </div>
            <div className="checkbox-group">
                <input
                    type="checkbox"
                    checked={showUnobtainableContent}
                    onChange={(e) => setShowUnobtainableContent(e.target.checked)}
                />
                <label>Show un-obtainable items</label>
            </div>
            <div className="form-group">
                <label>Faction:</label>
                <select value={playerFaction} onChange={(e) => {
                    const newFaction = e.target.value;
                    setplayerFaction(newFaction);
                }}>
                    {factionOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <label>Race:</label>
                <select value={playerRace} onChange={(e) => {
                    const newRace = e.target.value;
                    setplayerRace(newRace);
                }}>
                    {raceOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <label>Class:</label>
                <select value={playerClass} onChange={(e) => {
                    const newClass = e.target.value;
                    setplayerClass(newClass);
                }}>
                    {classOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <button className="import-data-button" onClick={handleImportDataClick}>Import Data</button>
            <div className="button-container">
                <div className="reset-collection-button">
                    <button onClick={resetCollection}>Reset Collection</button>
                </div>
                <div className="settings-buttons">
                    <button onClick={saveSettings}>Save</button>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        if (user) {
            devLog("Updating local state based on userSettings prop:", userSettings);
            if (userSettings) {
                if (userSettings.hasOwnProperty('show_cataclysm_content')) {
                    setShowCataclysmContent(userSettings.show_cataclysm_content);
                }
                if (userSettings.hasOwnProperty('show_unobtainable_content')) {
                    setShowUnobtainableContent(userSettings.show_unobtainable_content);
                }
                if (userSettings.hasOwnProperty('player_faction')) {
                    setplayerFaction(userSettings.player_faction);
                }
                if (userSettings.hasOwnProperty('player_race')) {
                    setplayerRace(userSettings.player_race);
                }
                if (userSettings.hasOwnProperty('player_class')) {
                    setplayerClass(userSettings.player_class);
                }
            }
        }
    }, [userSettings, user]);

    const factionOptions = [
        { label: "Both", value: "AH" },
        { label: "Alliance", value: "A" },
        { label: "Horde", value: "H" }
    ];

    const raceOptions = [
        { label: "Human", value: 1 },
        { label: "Orc", value: 2 },
        { label: "Dwarf", value: 3 },
        { label: "Night Elf", value: 4 },
        { label: "Undead", value: 5 },
        { label: "Tauren", value: 6 },
        { label: "Gnome", value: 7 },
        { label: "Troll", value: 8 },
        { label: "Blood Elf", value: 10 },
        { label: "Draenei", value: 11 }
    ];

    const classOptions = [
        { label: "Warrior", value: 1 },
        { label: "Paladin", value: 2 },
        { label: "Hunter", value: 3 },
        { label: "Rogue", value: 4 },
        { label: "Priest", value: 5 },
        { label: "Death Knight", value: 6 },
        { label: "Shaman", value: 7 },
        { label: "Mage", value: 8 },
        { label: "Warlock", value: 9 },
        { label: "Druid", value: 11 }
    ];

    devLog("Show Cataclysm Content state in Settings.js:", showCataclysmContent);
    devLog("Show Unobtainable Content state in Settings.js:", showUnobtainableContent);
    devLog("Player faction state in Settings.js:", playerFaction);
    devLog("Player race state in Settings.js:", playerRace);
    devLog("Player class state in Settings.js:", playerClass);

    const saveSettings = () => {
        // Check if user is available
        if (!user || !user.id) {
            devError("User is not available.");
            return;
        }

        const url = `${process.env.REACT_APP_BACKEND_URL}/saveUserSettings?userId=${user.id}`;
        const payload = {
            userId: user.id,
            showCataclysmContent: showCataclysmContent ? 1 : 0,
            showUnobtainableContent: showUnobtainableContent ? 1 : 0,
            playerFaction: playerFaction,
            playerRace: playerRace,
            playerClass: playerClass
        };

        devLog("Payload being sent to backend:", payload);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setUserSettings({
                        show_cataclysm_content: showCataclysmContent,
                        show_unobtainable_content: showUnobtainableContent,
                        player_faction: playerFaction,
                        player_race: Number(playerRace),
                        player_class: Number(playerClass)
                    });

                    // Close the settings widget
                    onClose();

                    // Display a toast notification
                    toast.success("Settings saved", { autoClose: 2000 });
                } else {
                    // Optionally, you can handle errors here, for example:
                    toast.error("Failed to save settings", { autoClose: 2000 });
                }
            });
    };

    if (!isOpen) return null;

    if (!user || !user.id) {
        return <LoggedOutReturn />;
    } else {
        return <LoggedInReturn />;
    }
};

export default SettingsWidget;