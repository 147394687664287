import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import './Items.css';
import ProgressBar from './ProgressBar';
import localforage from 'localforage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { devLog, devError } from '../../utils/Logger';
import CollectionResetContext from '../../context/CollectionResetContext';

const CACHE_DURATION = 604800000;

function ItemsDisplay({ endpoint, itemType, urlConstructor, categoryOrder, subCategoryOrder, userSettings, showCustomTooltip, hideCustomTooltip, isAchievementPage, showItems = true }) {
    const { collectionResetTimestamp } = useContext(CollectionResetContext);
    const { category: encodedCategory } = useParams();
    const [items, setItems] = useState([]);
    const { user } = useContext(AuthContext);
    const [obtainedItems, setObtainedItems] = useState([]);
    devLog("User settings in ItemsDisplay:", userSettings);

    let category = encodedCategory;

    if (endpoint === "achievements" && encodedCategory) {
        category = decodeURIComponent(encodedCategory.replace(/-/g, ' '));

        // Special handling for "Dungeons & Raids"
        if (category === "dungeons raids") {
            category = "Dungeons & Raids";
        }
    }
    
    const shouldDisplayItem = useCallback((item) => {
        // Check for Cataclysm content
        if (item.IsCataclysm && userSettings && !userSettings.show_cataclysm_content) {
            return false;
        }

        // Check for unobtainable content
        if (!item.IsObtainable && userSettings && !userSettings.show_unobtainable_content) {
            return false;
        }

        // Check for faction
        if (userSettings && userSettings.player_faction !== "AH" && item.Faction !== "AH" && item.Faction !== userSettings.player_faction) {
            return false;
        }

        // Check for race
        if (userSettings && userSettings.player_race !== -1 && item.AllowableRaces !== -1 && item.AllowableRaces !== userSettings.player_race) {
            return false;
        }

        // Check for class
        if (userSettings && userSettings.player_class !== -1 && item.AllowableClasses !== -1 && item.AllowableClasses !== userSettings.player_class) {
            return false;
        }

        return true;
    }, [userSettings]);

    let displayedObtainedItems = obtainedItems;

    if (isAchievementPage) {
        displayedObtainedItems = obtainedItems.filter(obtainedItem => {
            const correspondingItem = items.find(item => item.ID === obtainedItem[itemType + '_id']);
            const isMatch = correspondingItem && correspondingItem.Category.toLowerCase() === category.toLowerCase();
            devLog("Checking obtained item:", obtainedItem, "against full list item:", correspondingItem, "Is match?", isMatch);
            return isMatch;
        });
        devLog("Filtered obtained items for category", category, ":", displayedObtainedItems);
    }

    useEffect(() => {
        const url = category
            ? `${process.env.REACT_APP_BACKEND_URL}/${endpoint}?category=${encodeURIComponent(category)}`
            : `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`;

        devLog("Fetching items from URL:", url); // Log the URL
        devLog("Timestamp received: ", collectionResetTimestamp)

        localforage.getItem(url).then(cachedData => {
            const currentTime = new Date().getTime();
            if (cachedData && (currentTime - cachedData.timestamp) <= CACHE_DURATION) {
                devLog("Using cached items data:", cachedData.data); // Log the cached data
                setItems(cachedData.data);
                setTotalItems(cachedData.data.filter(shouldDisplayItem).length);
            } else {
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        localforage.setItem(url, {
                            timestamp: currentTime,
                            data: data
                        });
                        setItems(data);
                        setTotalItems(data.filter(shouldDisplayItem).length);
                        devLog("Saving cached items data:", data); // Log the cached data
                    })
                    .catch(error => devError(`Error fetching ${itemType}:`, error));
            }
        });
    }, [category, endpoint, itemType, userSettings, shouldDisplayItem, collectionResetTimestamp]);

    useEffect(() => {
        if (user) {
            const url = `${process.env.REACT_APP_BACKEND_URL}/${endpoint}/obtained?accountId=${user.id}`;
            const cacheKey = `obtained-${endpoint}-${user.id}`; // Unique cache key for each user

            devLog("Fetching obtained items from URL:", url);

            localforage.getItem(cacheKey).then(cachedData => {
                const currentTime = new Date().getTime();
                if (cachedData && (currentTime - cachedData.timestamp) <= CACHE_DURATION) {
                    devLog("Using cached obtained items data:", cachedData.data); // Log the cached data
                    setObtainedItems(cachedData.data);
                    setCollectedItems(cachedData.data.length);
                } else {
                    fetch(url)
                        .then(response => response.json())
                        .then(data => {
                            devLog("Received obtained items data:", data); // Log the received data
                            localforage.setItem(cacheKey, {
                                timestamp: currentTime,
                                data: data
                            });
                            setObtainedItems(data);
                            setCollectedItems(data.length);
                        })
                        .catch(error => devError(`Error fetching obtained ${itemType}:`, error));
                }
            });
        }
    }, [user, endpoint, itemType, collectionResetTimestamp]);

    function groupByCategory(items) {
        return items.reduce((acc, item) => {
            if (!acc[item.Category]) {
                acc[item.Category] = {};
            }
            if (!acc[item.Category][item.SubCategory || 'General']) {
                acc[item.Category][item.SubCategory || 'General'] = [];
            }
            acc[item.Category][item.SubCategory || 'General'].push(item);
            return acc;
        }, {});
    }
    const groupedItems = groupByCategory(items);

    const handleItemClick = (itemId) => {
        if (!user) {
            toast.error("You must be logged in to mark items as obtained.");
            return;
        }

        const isObtained = obtainedItems.some(item => item[itemType + '_id'] === itemId);
        const action = isObtained ? 'remove' : 'mark';

        const obtainedItemsCacheKey = `obtained-items-${user.id}`; // Unique cache key for each user
        localforage.removeItem(obtainedItemsCacheKey); // Clear the cache for the specific user's obtained items

        devLog(`Handling item click for item ID: ${itemId}. Action: ${action}`); // Log item ID and action

        const payload = {
            accountId: user.id,
            [itemType + 'Id']: itemId
        };

        devLog("Sending request payload:", payload); // Log the request payload

        // Determine the HTTP method based on the action
        const httpMethod = action === 'mark' ? 'POST' : 'DELETE';

        fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}/${action}`, {
            method: httpMethod,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                devLog("Response data after marking/unmarking item:", data); // Log the response data
                if (data.success) {
                    if (isObtained) {
                        setObtainedItems(prev => prev.filter(item => item[itemType + '_id'] !== itemId));
                        setCollectedItems(prevCount => prevCount - 1); // Decrease the count
                    } else {
                        setObtainedItems(prev => [...prev, { account_id: user.id, [itemType + '_id']: itemId }]);
                        setCollectedItems(prevCount => prevCount + 1); // Increase the count
                    }

                    // Clear the cache for obtained items
                    const cacheKey = `obtained-${endpoint}-${user.id}`;
                    localforage.removeItem(cacheKey).then(() => {
                        devLog("Cleared cache for obtained items after update.");
                    });
                }
            })
            .catch(error => devError(`Error updating ${itemType}:`, error));
    };

    const [totalItems, setTotalItems] = useState(0);
    const [, setCollectedItems] = useState(0);

    const orderedCategories = categoryOrder
        ? categoryOrder.filter(cat => groupedItems[cat])
        : Object.keys(groupedItems);

    return (
        <div>
            {/* Progress Bar */}
            <div className="progress-container-wrapper">
                <ProgressBar
                    pageType="itemsDisplay"
                    currentValue={displayedObtainedItems.length}
                    maxValue={totalItems}
                    label={endpoint}
                />
            </div>
            <div className="line-separator-items"></div>
            {/* Categories and Items */}
            <div className="categories-container">
                {showItems && orderedCategories
                    .filter(cat => {
                        const subCats = groupedItems[cat];
                        return Object.keys(subCats).some(subCat => subCats[subCat].some(shouldDisplayItem));
                    })
                    .map(category => {
                        const subCategories = groupedItems[category] || {};
                        return (
                            <div key={category}>
                                <h3>{category}</h3>
                                <div className="subcategories-grid">
                                    {Object.keys(subCategories)
                                        .filter(subCat => subCategories[subCat].some(shouldDisplayItem))
                                        .sort((a, b) => {
                                            const orderA = subCategoryOrder && subCategoryOrder[category] ? subCategoryOrder[category].indexOf(a) : 0;
                                            const orderB = subCategoryOrder && subCategoryOrder[category] ? subCategoryOrder[category].indexOf(b) : 0;
                                            return orderA - orderB;
                                        })
                                        .map(subCategory => (
                                            <div key={subCategory}>
                                                {subCategory !== 'General' && <p className='subcategoryHeader'>{subCategory}</p>}
                                                <div className="items-row">
                                                    {subCategories[subCategory].filter(shouldDisplayItem).map(item => {
                                                        if (endpoint === "factions" || endpoint === "titles") {
                                                            return (
                                                                <a
                                                                    key={item.ID}
                                                                    href={urlConstructor ? urlConstructor(item) : `//www.wowhead.com/item=${item.ItemID}`}
                                                                    rel="noopener noreferrer"
                                                                    onClick={e => {
                                                                        e.preventDefault();  // Prevent navigation
                                                                        handleItemClick(item.ID);
                                                                    }}
                                                                >
                                                                    <div className="icon-wrapper">
                                                                        <div
                                                                            className={`item-icon ${obtainedItems.some(i => i[itemType + '_id'] === item.ID) ? 'obtained' : ''}`}
                                                                            style={{ backgroundImage: `url(https://wow.zamimg.com/images/wow/icons/large/${item.Icon.toLowerCase()}.jpg)` }}
                                                                            onMouseOver={(e) => showCustomTooltip && showCustomTooltip(item, e)}
                                                                            onMouseOut={hideCustomTooltip && hideCustomTooltip}
                                                                        ></div>
                                                                    </div>
                                                                </a>
                                                            );
                                                        } else {
                                                            return (
                                                                <a
                                                                    key={item.ID}
                                                                    href={urlConstructor ? urlConstructor(item) : `//www.wowhead.com/item=${item.ItemID}`}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <div className="icon-wrapper" onClick={e => {
                                                                        e.preventDefault();  // Prevent navigation
                                                                        handleItemClick(item.ID);
                                                                    }}>
                                                                        <div
                                                                            className={`item-icon ${obtainedItems.some(i => i[itemType + '_id'] === item.ID) ? 'obtained' : ''}`}
                                                                            style={{ backgroundImage: `url(https://wow.zamimg.com/images/wow/icons/large/${item.Icon.toLowerCase()}.jpg)` }}
                                                                        ></div>
                                                                    </div>
                                                                </a>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default ItemsDisplay;
