import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { AuthContext } from '../../context/auth';
import SettingsWidget from '../Settings/Settings';
import { devLog } from '../../utils/Logger';
import ImportStringContext from '../../context/ImportStringContext';
import { handleImportData } from '../../utils/handleImportData';

const Navbar = ({ setUserSettings, userSettings, collectionResetTimestamp, setCollectionResetTimestamp }) => {
  devLog("User settings prop in Navbar.js:", userSettings);
  const { user, logout } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { importString } = useContext(ImportStringContext);

  useEffect(() => {
    if (user && importString) { // Check if user exists
      devLog("Here is the importString:", importString);

      // Call the utility function to handle the import data
      handleImportData(importString, user.id, setCollectionResetTimestamp);
    }
  }, [importString, user, setCollectionResetTimestamp]);

  const AchievementCategories = [
    "Dungeons & Raids", "Exploration", "Feats of Strength", "General",
    "Player vs. Player", "Professions", "Quests", "Reputation", "World Events"
  ];

  return (
    <div className="navbar">
      <div className="navbar-inner">
        <Link to="/" className="logo-button">
          <img src="/images/WebsiteIcon.jpg" alt="Logo" className="logo-image" />
        </Link>
        <div className="navbar-buttons">
          <div className="navbar-button dropdown" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
            Achievements
            {dropdownOpen && (
              <div className="dropdown-content">
                {AchievementCategories.map(category => (
                  <Link to={`/achievements/${category.toLowerCase().replace(/ & | /g, '-')}`} key={category}>
                    {category}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link to="/heirlooms" className="navbar-button">Heirlooms</Link>
          <Link to="/mounts" className="navbar-button">Mounts</Link>
          <Link to="/pets" className="navbar-button">Pets</Link>
          <Link to="/factions" className="navbar-button">Factions</Link>
          <Link to="/titles" className="navbar-button">Titles</Link>
          <Link to="/toys" className="navbar-button">Toys</Link>
        </div>
      </div>
      <div className="user-logout-holder">
        {user ? <p className='username'>{user.username}</p> : null}
        <i className="fas fa-cog icon-button" onClick={() => setIsSettingsOpen(prevState => !prevState)}></i>
        <SettingsWidget
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          collectionResetTimestamp={collectionResetTimestamp}
          setCollectionResetTimestamp={setCollectionResetTimestamp}
        />
        {user ? (
          <>
            <div></div> {/* Empty div to reserve space for Register button */}
            <div></div> {/* Empty div to reserve space for Login button */}
            <button className="icon-button" onClick={logout}>
              <i className="fas fa-sign-out-alt"></i>
            </button>
          </>
        ) : (
          <>
            <Link to="/register" className="icon-button">
              <i className="fas fa-user-plus"></i>
            </Link>
            <Link to="/login" className="icon-button">
              <i className="fas fa-sign-in-alt"></i>
            </Link>
            <div></div> {/* Empty div to reserve space for Logout button */}
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;
