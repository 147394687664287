import React from 'react';

function ProgressBar({ currentValue, maxValue, label, type, pageType }) {
    const percentage = (currentValue / maxValue) * 100;

    // Conditionally import the CSS based on the pageType
    if (pageType === "home") {
        require('../Home/HomeProgressBar.css');
    } else if (pageType === "itemsDisplay") {
        require('./ItemsDisplayProgressBar.css');
    }

    const classPrefix = pageType === "home" ? "home-" : "";

    return (
        <div className={`${classPrefix}progress-container ${type}`}>
            {label && <span className={`${classPrefix}progress-label`}>{label}</span>}
            <div className={`${classPrefix}progress-bar`}>
                <div className={`${classPrefix}progress-bar-fill`} style={{ width: `${percentage}%` }}></div>
                <span className={`${classPrefix}progress-text`}>{currentValue}/{maxValue} ({Math.round(percentage)}%)</span>
            </div>
        </div>
    );
}

export default ProgressBar;