import ItemsDisplay from '../Shared/ItemsDisplay';

function Achievements({ userSettings }) {

    const achievementCategoryOrder = [
        "General",
        "Dungeons & Raids",
        "Exploration",
        "Feats of Strength",
        "Player vs. Player",
        "Professions",
        "Quests",
        "Reputation",
        "World Events"
    ];

    const achievementSubCategoryOrder = {
        "Dungeons & Raids": ["Other", "Cataclysm", "Cataclysm Raid", "Cataclysm Dungeon", "Wrath of the Lich King", "Wrath of the Lich King Raid", "Wrath of the Lich King Raid (25 player)", "Wrath of the Lich King Raid (10 player)", "Secrets of Ulduar", "Call of the Crusade", "Wrath of the Lich King Dungeon", "The Burning Crusade", "The Burning Crusade Raid", "The Burning Crusade Dungeon", "Classic", "Classic Raid", "Classic Dungeon"],
        "Exploration": ["Other", "Cataclysm", "Northrend", "Outland", "Kalimdor", "Eastern Kingdoms"],
        "Player vs. Player": ["Other", "Tol Barad", "Twin Peaks", "Battle for Gilneas", "Isle of Conquest", "Wintergrasp", "Strand of the Ancients", "Eye of the Storm", "Alterac Valley", "Arathi Basin", "Warsong Gulch", "Battlegrounds", "Rated Battleground", "Arena"],
        "Quests": ["Other", "Cataclysm", "Northrend", "Outland", "Kalimdor", "Eastern Kingdoms"],
        "Reputation": ["Other", "Cataclysm", "Wrath of the Lich King", "The Burning Crusade", "Classic"]
    };

    // We first use /wotlk/achievement for majority of achievements.
    // We use wowhead.com/achievement for anything that is cataclysm
    const achievementURLConstructor = (item) => {
        if ([5364, 5365].includes(item.ID)) {
            return `//www.wowhead.com/wotlk/quest=3405`;
        } else if ([5259].includes(item.ID)) {
            return `//www.wowhead.com/achievement=5223`;
        } else if ([5846].includes(item.ID)) {
            return `//www.wowhead.com/achievement=5845`;
        } else if ([5852].includes(item.ID)) {
            return `//www.wowhead.com/achievement=5851`;
        } else if ([4983].includes(item.ID)) {
            return `//www.wowhead.com/achievement=4875`;
        } else if ([4898].includes(item.ID)) {
            return `//www.wowhead.com/achievement=12429`;
        } else if ([4907].includes(item.ID)) {
            return `//www.wowhead.com/achievement=12430`;
        } else if (item.IsCataclysm) {
            return `//www.wowhead.com/achievement=${item.ID}`;
        } else {
            return `//www.wowhead.com/wotlk/achievement=${item.ID}`;
        }
    };
    
    return (
        <ItemsDisplay 
            endpoint="achievements"
            itemType="achievement"
            urlConstructor={achievementURLConstructor}
            categoryOrder={achievementCategoryOrder}
            subCategoryOrder={achievementSubCategoryOrder}
            userSettings={userSettings}
            isAchievementPage={true}
        />
    );
}

export default Achievements;