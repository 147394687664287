import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import { AuthContext, AuthProvider } from './context/auth';
import { ToastContainer, toast } from 'react-toastify';
import Achievements from './Components/Achievements/Achievements';
import Heirlooms from './Components/Heirlooms/Heirlooms';
import Mounts from './Components/Mounts/Mounts';
import Pets from './Components/Pets/Pets';
import Titles from './Components/Titles/Titles';
import Factions from './Components/Factions/Factions';
import Toys from './Components/Toys/Toys';
import Footer from './Components/Shared/Footer';
import './App.css';
import { devLog, devError } from './utils/Logger';
import CollectionResetContext from './context/CollectionResetContext';
import ImportStringContext from './context/ImportStringContext';
import GoogleAnalyticsTracker from './utils/GoogleAnalyticsTracker';
const Register = React.lazy(() => import('./Components/Register/Register'));
const Login = React.lazy(() => import('./Components/Login/Login'));
const LoggedRoute = React.lazy(() => import('./utils/LoggedRoute'));

window.onerror = function (message, source, lineno, colno, error) {
  devError("An error occurred:", message, "source: ", source, "line number: ", lineno);
  // Prevent the default browser error message
  return true;
};

window.addEventListener('unhandledrejection', (event) => {
  devError("Unhandled promise rejection:", event.reason);
  // Prevent the default browser error message
  event.preventDefault();
});

const App = () => {
  const [collectionResetTimestamp, setCollectionResetTimestamp] = useState(null);
  const [importString, setImportString] = useState("");
  const { user } = useContext(AuthContext);
  const [userSettings, setUserSettings] = useState({
    show_cataclysm_content: false,
    show_unobtainable_content: false,
    player_faction: "AH",
    player_race: -1,
    player_class: -1
  });

  useEffect(() => {
    if (user) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/getUserSettings?userId=${user.id}`;
      devLog("Fetching user settings for user ID:", user.id, "from URL:", url);
      devLog("User from AuthContext in App.js:", user);
      fetch(url)
        .then(response => response.json())
        .then(data => {
          devLog("Fetched user settings from backend:", data);
          setUserSettings({
            show_cataclysm_content: Number(data.show_cataclysm_content),
            show_unobtainable_content: Number(data.show_unobtainable_content),
            player_faction: (data.player_faction),
            player_race: Number(data.player_race),
            player_class: Number(data.player_class)
          });
          devLog("data.player_faction in App.js: ", data.player_faction)
          devLog("data.player_race in App.js: ", data.player_race)
          devLog("data.player_class in App.js: ", data.player_class)
        })
        .catch(error => devError('There was a problem with the fetch operation:', error.message));
    }
  }, [user]);

  return (
    <>
      <CollectionResetContext.Provider value={{ collectionResetTimestamp, setCollectionResetTimestamp }}>
        <ImportStringContext.Provider value={{ importString, setImportString }}>
          <ToastContainer position={toast.POSITION.TOP_CENTER}
          />
          <AuthProvider>
            <Suspense
            >
              <Router>
                <GoogleAnalyticsTracker />
                <div className="App">
                  <Navbar setUserSettings={setUserSettings} userSettings={userSettings} collectionResetTimestamp={collectionResetTimestamp} setCollectionResetTimestamp={setCollectionResetTimestamp} />
                  <div className="main-content">
                    <Routes>
                      <Route path="/" element={<Home setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/achievements/:category" element={<Achievements setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/heirlooms" element={<Heirlooms setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/mounts" element={<Mounts setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/titles" element={<Titles setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/pets" element={<Pets setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/factions" element={<Factions setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route path="/toys" element={<Toys setUserSettings={setUserSettings} userSettings={userSettings} />} />
                      <Route
                        path="/register"
                        element={
                          <LoggedRoute user={user}>
                            <Register />
                          </LoggedRoute>
                        }
                      />
                      <Route
                        path="/login"
                        element={
                          <LoggedRoute user={user}>
                            <Login />
                          </LoggedRoute>
                        }
                      />
                    </Routes>
                  </div>
                  <Footer />
                </div>
              </Router>
            </Suspense>
          </AuthProvider>
        </ImportStringContext.Provider>
      </CollectionResetContext.Provider>
    </>
  );
};

export default App;
