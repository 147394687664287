import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <a href="https://discord.gg/GtfJMfE24k" target="_blank" rel="noreferrer">Discord</a>
            <a href="https://discord.gg/GtfJMfE24k" target="_blank" rel="noreferrer">FAQ</a>
        </footer>
    );
}

export default Footer;
