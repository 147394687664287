import React, { useState, useEffect, useContext, useCallback } from 'react';
import './Home.css';
import ProgressBar from '../Shared/ProgressBar';
import { AuthContext } from '../../context/auth';
import localforage from 'localforage';
import { devLog, devError } from '../../utils/Logger';
import CollectionResetContext from '../../context/CollectionResetContext';

const CACHE_DURATION = 604800000;

const Home = ({ userSettings }) => {
    const { collectionResetTimestamp } = useContext(CollectionResetContext);
    const { user } = useContext(AuthContext);
    const [allItems, setAllItems] = useState({});
    const [obtainedItems, setObtainedItems] = useState({});
    const shouldDisplayItem = useCallback((item) => {
        // Check for Cataclysm content
        if (item.IsCataclysm && userSettings && !userSettings.show_cataclysm_content) {
            return false;
        }
        // Check for unobtainable content
        if (!item.IsObtainable && userSettings && !userSettings.show_unobtainable_content) {
            return false;
        }
        // Check for faction
        if (userSettings && userSettings.player_faction !== "AH" && item.Faction !== "AH" && item.Faction !== userSettings.player_faction) {
            return false;
        }
        // Check for race
        if (userSettings && userSettings.player_race !== -1 && item.AllowableRaces !== -1 && item.AllowableRaces !== userSettings.player_race) {
            return false;
        }
        // Check for class
        if (userSettings && userSettings.player_class !== -1 && item.AllowableClasses !== -1 && item.AllowableClasses !== userSettings.player_class) {
            return false;
        }
        return true;
    }, [userSettings]);

    const LoggedOutReturn = () => (
        <div className="home-container">
            <h2>Welcome to the Stats Overview Page! Please log in to see your stats</h2>
        </div>
    );

    const LoggedInReturn = () => (
        <div className="home-container">
            <h2>Progress Overview</h2>
            <div className="line-separator-home"></div>
            <div className="overall-progress">
            {/* Overall Progress Bar */}
            <ProgressBar
                pageType="home"
                type="overall"
                currentValue={overallObtainedItems}
                maxValue={overallTotalItems}
                label="Overall Progress"
            />
            </div>
            <div className="individual-progress-container">
                {Object.keys(allItems).map(endpoint => {
                    const totalForEndpoint = allItems[endpoint]?.filter(shouldDisplayItem).length || 0;
                    const obtainedCount = obtainedForEndpoint(endpoint, obtainedItems, allItems);
                    devLog(`Endpoint: ${endpoint}, Total: ${totalForEndpoint}, Obtained: ${obtainedCount}`);
                    return (
                        <ProgressBar
                            pageType="home"
                            type="individual"
                            key={endpoint}
                            currentValue={obtainedCount}
                            maxValue={totalForEndpoint}
                            label={endpoint.charAt(0).toUpperCase() + endpoint.slice(1)}
                        />
                    );
                })}
            </div> {/* End of the container */}
        </div>
    );

    useEffect(() => {
        if (user) {
            const allItemsUrl = `${process.env.REACT_APP_BACKEND_URL}/getAllItems`;
            const obtainedItemsUrl = `${process.env.REACT_APP_BACKEND_URL}/getAllObtainedItems?userId=${user.id}`;
            const obtainedItemsCacheKey = `obtained-items-${user.id}`; // Unique cache key for each user

            // Helper function to check if cached data is still valid
            const isCacheValid = (timestamp) => {
                return Date.now() - timestamp < CACHE_DURATION;
            };

            // Fetch all items
            localforage.getItem(allItemsUrl).then(cachedAllItems => {
                if (cachedAllItems && isCacheValid(cachedAllItems.timestamp)) {
                    devLog("Using cached all items data:", cachedAllItems.data);
                    setAllItems(cachedAllItems.data);
                } else {
                    fetch(allItemsUrl)
                        .then(response => response.json())
                        .then(data => {
                            localforage.setItem(allItemsUrl, { data, timestamp: Date.now() });
                            setAllItems(data);
                        })
                        .catch(error => devError('Error fetching all items:', error));
                }
            });

            // Fetch all obtained items for the user
            localforage.getItem(obtainedItemsCacheKey).then(cachedObtainedItems => {
                if (cachedObtainedItems && isCacheValid(cachedObtainedItems.timestamp)) {
                    devLog("Using cached obtained items data for user:", user.id, cachedObtainedItems.data);
                    setObtainedItems(cachedObtainedItems.data);
                } else {
                    fetch(obtainedItemsUrl)
                        .then(response => response.json())
                        .then(data => {
                            localforage.setItem(obtainedItemsCacheKey, { data, timestamp: Date.now() });
                            setObtainedItems(data);
                        })
                        .catch(error => devError('Error fetching obtained items for the user:', error));
                }
            });
        }
    }, [user, collectionResetTimestamp]);

    const obtainedForEndpoint = (endpoint, obtainedItems, allItems) => {
        // Construct the ID property name based on the endpoint
        const idPropertyName = `${endpoint.slice(0, -1)}_id`; // e.g., 'toy_id' for 'toys' endpoint

        return obtainedItems[endpoint]?.filter(obtainedItem => {
            const obtainedItemId = obtainedItem[idPropertyName];
            const correspondingAllItem = allItems[endpoint]?.find(item => item.ID === obtainedItemId);
            devLog(`Endpoint: ${endpoint}, Obtained Item ID: ${obtainedItemId}, Corresponding All Item:`, correspondingAllItem);
            return correspondingAllItem && shouldDisplayItem(correspondingAllItem);
        }).length || 0;
    };

    // Calculate overall totals
    const overallTotalItems = Object.keys(allItems).reduce((total, endpoint) => {
        return total + (allItems[endpoint]?.filter(shouldDisplayItem).length || 0);
    }, 0);

    const overallObtainedItems = Object.keys(obtainedItems).reduce((total, endpoint) => {
        return total + obtainedForEndpoint(endpoint, obtainedItems, allItems);
    }, 0);

    if (!user || !user.id) {
        return <LoggedOutReturn />;
    } else {
        return <LoggedInReturn />;
    }
};

export default Home;