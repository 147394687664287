import localforage from 'localforage';
import { devError, devLog } from '../utils/Logger';
const cacheEndpoints = ['achievements', 'heirlooms', 'mounts', 'pets', 'factions', 'titles', 'toys']

export const handleImportData = async (importString, userId, setCollectionResetTimestamp) => {

    const parseImportString = (importString) => {
        const categories = importString.split(';');
        const parsedData = {};

        categories.forEach(categoryString => {
            const [categoryName, idsString] = categoryString.split(':');
            const ids = idsString.split(',').map(Number);
            parsedData[categoryName] = ids;
        });

        return parsedData;
    };

    // This function maps the provided spellIDs to the corresponding mount IDs using the backend API
    const mapSpellIdsToMountIds = async (spellIds) => {
        const endpoint = "mounts";

        return fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`)
            .then(response => response.json())
            .then(data => {
                devLog("Fetched mounts data:", data);

                // Filter the mounts based on the provided spellIDs and map to their IDs
                const mappedIds = data.filter(mount => {
                    const isMatch = spellIds.includes(mount.SpelliD);
                    if (isMatch) {
                        devLog(`Found match for spell ID ${mount.SpelliD} with mount ID ${mount.ID}`);
                    }
                    return isMatch;
                }).map(mount => mount.ID);

                devLog("Mapped spell IDs to mount IDs:", mappedIds);
                return mappedIds;
            })
            .catch(error => {
                devError("Error fetching mounts:", error);
                return [];
            });
    };

    // This function maps the provided itemIDs to the corresponding toy IDs using the backend API
    const mapItemIdsToToyIds = async (itemIds) => {
        const endpoint = "toys"; // Assuming this is the correct endpoint for toys

        return fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`)
            .then(response => response.json())
            .then(data => {
                // Filter the toys based on the provided itemIDs and map to their IDs
                return data.filter(toy => itemIds.includes(toy.ItemID)).map(toy => toy.ID);
            })
            .catch(error => {
                devError("Error fetching toys:", error);
                return [];
            });
    };

    const markItemsAsObtained = (category, ids) => {
        devLog(`Marking items as obtained for category ${category} with IDs:`, ids);
        const payload = {
            accountId: userId,
            category: category,
            itemIds: ids
        };
    
        // Create an array of promises for cache-clearing operations
        const cacheClearPromises = [];
    
        // Clear the cache for all obtained items
        const obtainedItemsCacheKey = `obtained-items-${userId}`;
        cacheClearPromises.push(localforage.removeItem(obtainedItemsCacheKey));
    
        // Clear the cache for obtained items
        cacheEndpoints.forEach(ep => {
            const endpointSpecificCacheKey = `obtained-${ep}-${userId}`;
            cacheClearPromises.push(localforage.removeItem(endpointSpecificCacheKey));
        });
    
        // Wait for all cache-clearing operations to complete
        return Promise.all(cacheClearPromises)
            .then(() => {
                devLog("Cleared all caches after data import.");
    
                return fetch(`${process.env.REACT_APP_BACKEND_URL}/${category.toLowerCase()}/markMultiple`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    devLog("Successfully marked items as obtained:", data);
                    // Refresh content on page
                    setCollectionResetTimestamp(Date.now());
                    devLog("Ran setCollectionResetTimestamp");
                }
            })
            .catch(error => devError(`Error updating ${category} from parsed items:`, error));
    };    

    const parsedData = parseImportString(importString);
    const categoryConfig = {
        Achievements: { mapFunction: null },
        Factions: { mapFunction: null },
        Heirlooms: { mapFunction: null },
        Mounts: { mapFunction: mapSpellIdsToMountIds },
        Pets: { mapFunction: null },
        Titles: { mapFunction: null },
        Toys: { mapFunction: mapItemIdsToToyIds }
    };
    for (let category in parsedData) {
        if (categoryConfig[category]) {
            if (categoryConfig[category].mapFunction) {
                // If there's a mapping function, use it
                const mappedIds = await categoryConfig[category].mapFunction(parsedData[category]);
                markItemsAsObtained(category.toLowerCase(), mappedIds);
            } else {
                // If there's no mapping function, use the IDs directly
                markItemsAsObtained(category.toLowerCase(), parsedData[category]);
            }
        }
    }
};