import React, { useState } from 'react';
import ItemsDisplay from '../Shared/ItemsDisplay';
import factionData from './data_factions.json';

const factionMap = {};
factionData.RECORDS.forEach(faction => {
    factionMap[faction.ID] = faction;
});

function Factions({ userSettings }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState({ title: '', description: '' });
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const factionCategoryOrder = [
        "Factions",
        "Cataclysm",
        "Classic",
        "PVP",
        "The Burning Crusade",
        "Wrath of the Lich King"
    ];

    const factionSubCategoryOrder = {
        "Factions": ["Other"],
        "Cataclysm": ["Other"],
        "Classic": ["Other"],
        "PVP": ["Other"],
        "The Burning Crusade": ["Other"],
        "Wrath of the Lich King": ["Other"]
    };

    // We first use /wotlk/faction for majority of factions.
    // We use wowhead.com/faction for anything that is cataclysm
    const factionURLConstructor = (item) => {
        if (item.IsCataclysm) {
            return `//www.wowhead.com/faction=${item.ReputationID}`;
        } else {
            return `//www.wowhead.com/wotlk/faction=${item.ReputationID}`;
        }
    };

    const showTitleTooltip = (item, event) => {
        const factionInfo = factionMap[item.ID];
        if (factionInfo) {
            setTooltipContent({
                title: factionInfo.Title,
                description: factionInfo.Description
            });
            setTooltipPosition({
                x: event.clientX,
                y: event.clientY
            });
            setTooltipVisible(true);
        }
    };

    const hideTitleTooltip = () => {
        setTooltipVisible(false);
    };

    return (
        <div>
            {tooltipVisible && (
                <div
                    className="tooltip"
                    style={{
                        top: `${tooltipPosition.y}px`,
                        left: `${tooltipPosition.x}px`
                    }}
                >
                    <strong>{tooltipContent.title}</strong>
                    <p>{tooltipContent.description}</p>
                </div>
            )}

            <ItemsDisplay
                endpoint="factions"
                itemType="faction"
                urlConstructor={factionURLConstructor}
                categoryOrder={factionCategoryOrder}
                subCategoryOrder={factionSubCategoryOrder}
                userSettings={userSettings}
                showCustomTooltip={showTitleTooltip}
                hideCustomTooltip={hideTitleTooltip}
            />
        </div>
    );
}

export default Factions;
