// logger.js
export function devLog(...args) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(...args);
    }
}
  
export function devError(...args) {
    if (process.env.NODE_ENV !== 'production') {
        console.error(...args);
    }
}