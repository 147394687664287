import React, { useReducer, createContext } from 'react';
import jwtDecode from 'jwt-decode';

const initialState = {
    // user: null,
    user: localStorage.getItem('jwtTokenSA') ? jwtDecode(localStorage.getItem('jwtTokenSA')) : null,
};

if (localStorage.getItem('jwtTokenSA')) {
    const decodedToken = jwtDecode(localStorage.getItem('jwtTokenSA'));
    //devLog(decodedToken, ' decodedToken');
    if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('jwtTokenSA');
    } else {
        initialState.user = decodedToken;
    }
}

const AuthContext = createContext({
    user: initialState.user,
    login: (userData) => {},
    logout: () => {},
    updateToken: () => {},
});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    function login(userData) {
        localStorage.setItem('jwtTokenSA', userData.token);
        window.location.reload(false);

        dispatch({
            type: 'LOGIN',
            payload: userData,
        });
    }
    function logout() {
        localStorage.removeItem('jwtTokenSA');
        window.location.reload(false);
        dispatch({
            type: 'LOGOUT',
        });
    }
    function updateToken(token) {
        window.location.reload(false);
        localStorage.removeItem('jwtTokenSA');
        localStorage.setItem('jwtTokenSA', token);
    }

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                db: state.db,
                login,
                logout,
                updateToken,
            }}
            {...props}
        />
    );
}

export { AuthContext, AuthProvider };
