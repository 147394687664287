import ItemsDisplay from '../Shared/ItemsDisplay';

function Pets({ userSettings }) {

    const petCategoryOrder = [
        "Pets",
        "Cataclysm",
        "Wrath of the Lich King",
        "The Burning Crusade",
        "Classic",
        "World Event",
        "Profession",
        "Promotional",
        "Other"
    ];

    const petSubCategoryOrder = {
        "Pets": ["Collect", "Achievement"],
        "Cataclysm": ["Achievement", "Quest", "Rare", "Reputation", "Molten Front"],
        "Wrath of the Lich King": ["Quest", "Vendor", "Treasure", "Reputation", "Achievement", "World Drop", "Cracked Egg", "Argent Tournament"],
        "The Burning Crusade": ["Quest", "Vendor", "Reputation", "World Drop", "Raid Drop"],
        "Classic": ["Quest", "Vendor", "Alliance Vendor", "Horde Vendor", "Dungeon Drop", "World Drop", "World Drop: Eastern Kingdoms", "World Drop: Kalimdor"],
        "World Event": ["Lunar Festival", "Love is in the Air", "Noblegarden", "Children's Week", "Midsummer Fire Festival", "Brewfest", "Hallow's End", "DotD", "Pilgrim's Bounty", "Feast of Winter Veil", "Darkmoon Faire"],
        "Profession": ["Archaeology", "Cooking", "Enchanting", "Mining", "Jewelcrafting", "Engineering", "Fishing"],
        "Promotional": ["Authenticator"],
        "Other": ["Guild Vendor"]
    };

    // We first use /wotlk/item for majority of items.
    // We use wowhead.com/item for anything that is cataclysm
    const petURLConstructor = (item) => {
        if (item.IsCataclysm) {
            return `//www.wowhead.com/item=${item.ItemID}`;
        } else {
            return `//www.wowhead.com/wotlk/item=${item.ItemID}`;
        }
    };

    return (
        <ItemsDisplay 
            endpoint="pets"
            itemType="pet"
            urlConstructor={petURLConstructor}
            categoryOrder={petCategoryOrder}
            subCategoryOrder={petSubCategoryOrder}
            userSettings={userSettings}
        />
    );
}

export default Pets;
