import ItemsDisplay from '../Shared/ItemsDisplay';

function Mounts({ userSettings }) {

    const mountCategoryOrder = [
        "Mounts",
        "Cataclysm",
        "Wrath of the Lich King",
        "The Burning Crusade",
        "Classic",
        "Racial",
        "Professions",
        "PVP",
        "World Events",
        "Promotion",
        "Other"
    ];

    const mountSubCategoryOrder = {
        "Cataclysm": ["Achievement", "Quest", "Vendor", "Dungeon Drop", "Raid Drop", "Rare Spawn"],
        "Wrath of the Lich King": ["Achievement", "Quest", "Vendor", "Argent Tournament", "Reputation", "Dungeon Drop", "Raid Drop", "Rare Spawn"],
        "The Burning Crusade": ["Cenarion Expedition", "Kurenai/The Mag'har", "Netherwing", "Sha'tari Skyguard", "Vendor", "Dungeon Drop", "Raid Drop"],
        "Classic": ["Reputation", "Dungeon Drop", "Raid Drop"],
        "Racial": ["Human", "Dwarf", "Night Elf", "Gnome", "Draenei", "Worgen", "Orc", "Undead", "Tauren", "Troll", "Goblin", "Blood Elf"],
        "Professions": ["Alchemy", "Archaeology", "Engineering", "Fishing", "Tailoring"],
        "PVP": ["Achievement", "Honor", "Wintergrasp", "Halaa"],
        "World Events": ["Achievement", "Brewfest", "Hallow's End", "Love is in the Air", "Noblegarden", "Darkmoon Faire"],
        "Promotion": ["Collector's Edition"],
        "Other": ["Paladin", "Warlock", "Death Knight", "Guild Vendor"],
    };

    // Use different url for some special cases. Not super clean implementation but cba.
    // We first use /wotlk/item for majority of items. We use /wotlk/spell for things like paladin/warlock mounts
    // We use wowhead.com/item or wowhead.com/spell for anything that is cataclysm
    const mountURLConstructor = (item) => {
        if ([13819, 23214, 34769, 34767, 69820, 69826, 73629, 73630, 5784, 23161, 48778].includes(item.ItemID)) {
            return `//www.wowhead.com/wotlk/spell=${item.ItemID}`;
        } else if (item.IsCataclysm) {
            return `//www.wowhead.com/item=${item.ItemID}`;
        } else {
            return `//www.wowhead.com/wotlk/item=${item.ItemID}`;
        }
    };

    return (
        <ItemsDisplay 
            endpoint="mounts"
            itemType="mount"
            urlConstructor={mountURLConstructor}
            categoryOrder={mountCategoryOrder}
            subCategoryOrder={mountSubCategoryOrder}
            userSettings={userSettings}
        />
    );
}

export default Mounts;
