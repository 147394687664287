import ItemsDisplay from '../Shared/ItemsDisplay';

function Toys({ userSettings }) {

    const toyCategoryOrder = [
        "Promotional",
        "Wrath of the Lich King",
        "The Burning Crusade",
        "Classic",
    ];

    const toySubCategoryOrder = {
        "Promotional": ["Other"],
        "Wrath of the Lich King": ["Other", "Achievements", "Argent Tournament", "Engineering", "Fishing", "Raids", "Vendor", "World Drop", "World Events", "Other"],
        "The Burning Crusade": ["Engineering", "Dungeon Drops", "World Events", "Other"],
        "Classic": ["Engineering", "Quest", "World Drop", "World Events", "Other"]
    };

    const toyURLConstructor = (item) => {
        if ([184871, 198647].includes(item.ItemID)) {
            return `//www.wowhead.com/wotlk/item=${item.ItemID}`;
        } else if (item.IsCataclysm) {
            return `//www.wowhead.com/item=${item.ItemID}`;
        } else {
            return `//www.wowhead.com/wotlk/item=${item.ItemID}`;
        }
    };    

    return (
        <ItemsDisplay 
            endpoint="toys"
            itemType="toy"
            urlConstructor={toyURLConstructor}
            categoryOrder={toyCategoryOrder}
            subCategoryOrder={toySubCategoryOrder}
            userSettings={userSettings}
        />
    );
}

export default Toys;
