import ItemsDisplay from '../Shared/ItemsDisplay';

function Heirlooms({ userSettings }) {

    const heirloomCategoryOrder = [
        "PVE",
        "PVP",
        "Vendor",
        "Other"
    ];

    const heirloomSubCategoryOrder = {
        "PVE": ["Emblem of Heroism", "Justice Points"],
        "PVP": ["Stone Keeper's Shards"],
        "Vendor": ["Guild Vendor"],
        "Other": ["Stranglethorn Fishing Contest"]
    };

    // We first use /wotlk/item for majority of items.
    // We use wowhead.com/item for anything that is cataclysm
    const heirloomURLConstructor = (item) => {
        if (item.IsCataclysm) {
            return `//www.wowhead.com/item=${item.ItemID}`;
        } else {
            return `//www.wowhead.com/wotlk/item=${item.ItemID}`;
        }
    };

    return (
        <ItemsDisplay 
            endpoint="heirlooms"
            itemType="heirloom"
            urlConstructor={heirloomURLConstructor}
            categoryOrder={heirloomCategoryOrder}
            subCategoryOrder={heirloomSubCategoryOrder}
            userSettings={userSettings}
        />
    );
}

export default Heirlooms;
