import ItemsDisplay from '../Shared/ItemsDisplay';
import React, { useState } from 'react';
import titleData from './data_titles.json';

const titleMap = {};
titleData.RECORDS.forEach(title => {
    titleMap[title.ID] = title;
});

function Titles({ userSettings }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState({ title: '', description: '' });
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const titleCategoryOrder = [
        "Titles",
        "Cataclysm",
        "Wrath of the Lich King",
        "The Burning Crusade",
        "Classic",
        "Professions",
        "PVP",
        "World Events",
        "Legacy",
        "PVE"
    ];

    const titleSubCategoryOrder = {
        "Titles": [""],
    };

    // We first use /wotlk/title for majority of titles.
    // We use wowhead.com/title for anything that is cataclysm
    const titleURLConstructor = (item) => {
        if (item.IsCataclysm) {
            return `//www.wowhead.com/title=${item.ID}`;
        } else {
            return `//www.wowhead.com/wotlk/title=${item.ID}`;
        }
    };

    const showTitleTooltip = (item, event) => {
        const titleInfo = titleMap[item.ID];
        if (titleInfo) {
            const rect = event.target.getBoundingClientRect();
            setTooltipContent({
                title: titleInfo.Title,
            });
            setTooltipPosition({
                x: rect.left + window.scrollX + (rect.width / 2), // Centered horizontally relative to the icon
                y: rect.top + window.scrollY - 40 // 20 pixels above the icon
            });
            setTooltipVisible(true);
        }
    };

    const hideTitleTooltip = () => {
        setTooltipVisible(false);
    };

    return (
        <div>
            {tooltipVisible && (
                <div
                    className="tooltip"
                    style={{
                        top: `${tooltipPosition.y}px`,
                        left: `${tooltipPosition.x}px`
                    }}
                >
                    <strong>{tooltipContent.title}</strong>
                </div>
            )}

            <ItemsDisplay
                endpoint="titles"
                itemType="title"
                urlConstructor={titleURLConstructor}
                categoryOrder={titleCategoryOrder}
                subCategoryOrder={titleSubCategoryOrder}
                userSettings={userSettings}
                showCustomTooltip={showTitleTooltip}
                hideCustomTooltip={hideTitleTooltip}
            />
        </div>
    );
}

export default Titles;
