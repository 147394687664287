// GoogleAnalyticsTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-T4C279SW2J";

ReactGA.initialize(TRACKING_ID);

const GoogleAnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null; // This component doesn't render anything
};

export default GoogleAnalyticsTracker;